import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import DegustationImage from './img/vinica-degustation.jpg'

import Section from '../../../../Section'
import ImageOverlay from '../../../../ImageOverlay'

const Tasting = () => (
  <Section id="degustation" className="bg-lighter" fluid>
    <Row className="py-5 text-center text-lg-left">
      <Col xs={12} lg={6} className="d-flex flex-column justify-content-center">
        <h4 className="text-purple">Experience unique</h4>
        <h2 className="font-bitter text-pink pb-3">Dégustations</h2>
        <p>
          À l’étage, une salle est à disposition dans laquelle nous vous
          proposons des dégustations par thème et nous nous adaptons en fonction
          de vos besoins et envies!
        </p>
        <p>
          À partir de 50€ par personne, chaque jeudi et vendredi, dès 20h00.
        </p>
        <p>
          Vous pouvez réserver directement via
          <br />
          {' '}
          notre adresse mail:
          {' '}
          <a className="link-dark" href="mailto:info@vinica-lyon.fr">
            info@vinica-lyon.fr
          </a>
          {' '}
          ou par téléphone au:
          {' '}
          <a className="link-dark" href="tel:+330478588629">
            04.78.58.86.29
          </a>
        </p>
      </Col>
      <Col xs={12} lg={6}>
        <ImageOverlay src={DegustationImage} fluid rounded />
      </Col>
    </Row>
  </Section>
)

export default Tasting
