import React, { useEffect } from 'react'
import HeroSection from './HeroSection'
import Sections from './Sections'

const HomePage = () => {
  useEffect(() => {
    if (!window.location.hash) window.scrollTo({ top: 0 })
  }, [])

  return (
    <>
      <HeroSection />
      <Sections />
    </>
  )
}

export default HomePage
