import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import ImageOverlay from '../../../../ImageOverlay'

import Section from '../../../../Section'

import ClementImage from './img/clement.jpg'
import VinicaImage from './img/vinica-interior.jpg'

const OurStory = () => (
  <Section id="la-cave" className="bg-lighter py-5" fluid>
    <Row className="text-center text-lg-left">
      <Col
        xs={{ span: 12, order: 'last' }}
        lg={{ span: 6, order: 'first' }}
      >
        <ImageOverlay src={VinicaImage} />
      </Col>
      <Col xs={12} lg={6} className="d-flex flex-column justify-content-center">
        <h4 className="text-purple">Notre histoire</h4>
        <h2 className="font-bitter text-pink pb-3">Vinica</h2>
        <p>
          Vinica de son origine slovaque, signifie vignoble. Une cave à vin
          créée par deux amis, dont l’un Slovaque: Lukas et l’autre, Français:
          Clément. Tous deux ont voulu apporter leurs savoirs et leur origine,
          bercés par la restauration et principalement les vins depuis plusieurs
          années.
        </p>
        <p>
          Vinica est composée en trois parties, la zone de vente située au
          Rez-de-chaussée où vous retrouverez la plus grosse partie de notre
          sélection. L’étage, qui est aménagé en salle de dégustation équipée.
          Et enfin, le sous-sol où réside la cave souterraine où des flacons un
          peu plus confidentiels sont classés.
        </p>
      </Col>
    </Row>
    <Row className="py-5 text-center text-lg-left">
      <Col xs={12} lg={6} className="d-flex flex-column justify-content-center">
        <h4 className="text-purple">Notre caviste</h4>
        <h2 className="font-bitter text-pink pb-3">Clément</h2>
        <p>
          Clément a fait ses débuts dans le monde professionnel à la Villa
          Florentine et y est resté pendant quatre années consécutives.
        </p>
        <p>
          Par la suite, il est devenu sommelier à l’Hôtel de Ville de Crissier
          en Suisse à côté de Lausanne, un restaurant trois étoiles élu meilleur
          restaurant du monde en 2016.
        </p>
        <p>
          Après cette belle expérience, il a travaillé pendant un an à la Maison
          Wenger, un restaurant deux étoiles situé dans le Jura Suisse.
        </p>
      </Col>
      <Col xs={12} lg={6} className="d-flex flex-column">
        <ImageOverlay src={ClementImage} />
      </Col>
    </Row>
  </Section>
)

export default OurStory
