import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Section from '../../../Section'

import VinicaFront from './img/vinica-front.jpg'
import ImageOverlay from '../../../ImageOverlay'

const HeroSection = () => (
  <Section id="accueil" style={{ marginTop: '75px' }} fluid>
    <Row
      className="min-vh-100 align-items-center py-5 py-lg-0 pb-5 text-center text-lg-left"
    >
      <Col xs={12} lg={6}>
        <h1 className="text-purple h4">Vinica</h1>
        <h2 className="text-pink font-bitter pb-3">
          Une cave à vins située sur les pentes de la croix-rousse
        </h2>
        <p>
          Cette cave vous offre la possibilité de trouver des vins de qualité
          principalement français mais aussi orientés sur la Vallée du Rhône,
          le Beaujolais et les Côteaux du Lyonnais.
        </p>
        <p>
          Une proximité qui permet d’avoir un lien direct avec les producteurs
          de la région.
        </p>
      </Col>
      <Col xs={12} lg={6}>
        <ImageOverlay src={VinicaFront} alt="vinica" />
      </Col>
    </Row>
  </Section>
)

export default HeroSection
