import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import Image from 'react-bootstrap/Image'

import * as styles from './index.module.scss'

const ImageOverlay = ({ text, ...otherProps }) => (
  <div className="position-relative">
    <div className="position-relative">
      <Image {...otherProps} className="shadow-lg" fluid rounded />
      <div className={classNames(styles.imageOverlay, 'rounded')} />
    </div>
    {text && (
      <h4 className={classNames(styles.overlayText, 'font-bitter text-white')}>
        {text}
      </h4>
    )}
  </div>
)

ImageOverlay.defaultProps = {
  text: undefined
}

ImageOverlay.propTypes = {
  text: PropTypes.string
}

export default ImageOverlay
