import React from 'react'

import Tasting from './Tasting'
import OurStory from './OurStory'
import Wine from './Wine'

const Sections = () => (
  <>
    <OurStory />
    <Wine />
    <Tasting />
  </>
)

export default Sections
