import * as React from 'react'

import Layout from '../components/Layout'
import HomePage from '../components/pages/HomePage'

import Seo from '../components/Seo'

const IndexPage = () => (
  <Layout>
    <Seo
      title="Vinica - Cave à vins Lyon"
      description="Une cave à vins située sur les pentes de la croix-rousse, ouverte du lundi au samedi."
      keywords={[
        'cave à vins',
        'caviste',
        'Lyon',
        'dégustation',
        'épicerie',
        'local',
        'proximité'
      ]}
    />
    <HomePage />
  </Layout>
)

export default IndexPage
