import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import WinesImage from './img/vinica-wines.jpg'
import FoodImage from './img/vinica-food.jpg'
import SpiritusImage from './img/vinica-spiritus.jpg'
import LiquourImage from './img/vinica-liquour.jpg'

import Section from '../../../../Section'
import ImageOverlay from '../../../../ImageOverlay'

const Wine = () => (
  <Section id="les-vins" fluid>
    <Row className="py-5 text-center">
      <Col
        xs={12}
        lg={{ span: 8, offset: 2 }}
        className="d-flex flex-column justify-content-center"
      >
        <h4 className="text-purple">Français et locaux</h4>
        <h2 className="font-bitter text-pink pb-3">Vins et Spiritueux</h2>
        <p>
          Afin d’animer vos débuts comme vos fins de soirées, vous retrouverez
          au sein de notre cave, un large panel de liqueurs, spiritueux, bières
          et vins basés principalement sur le vignoble français.
        </p>
        <p>
          Vous pourrez y découvrir également une partie épicerie
          <br />
          (saucissons, terrines, toastinades et bien d’autres encore...).
        </p>
      </Col>
    </Row>
    <Row className="text-center pb-5">
      <Col xs={12} md={6} lg={3}>
        <ImageOverlay src={WinesImage} text="Les Vins" alt="Les Vins" />
      </Col>
      <Col xs={12} md={6} lg={3} className="pt-5 pt-md-0">
        <ImageOverlay
          src={SpiritusImage}
          text="Les Spiritueux"
          alt="Les Spiritueux"
        />
      </Col>
      <Col xs={12} md={6} lg={3} className="pt-5 pt-lg-0">
        <ImageOverlay
          src={LiquourImage}
          text="Les Liqueurs"
          alt="Les Liqueurs"
        />
      </Col>
      <Col xs={12} md={6} lg={3} className="pt-5 pt-lg-0">
        <ImageOverlay src={FoodImage} text="L'épicerie" alt="L'épicerie" />
      </Col>
    </Row>
  </Section>
)

export default Wine
